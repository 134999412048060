import { useState, useEffect } from 'react';

import SearchField from '../components/SearchField';
import Results from '../components/Result';

const MainScreen = () => {
  let defValue = {
    income: '',
    retired: '',
    fulltime: '',
    qualification: '',
    employed: '',
    withdrawals: '',
    tax: '1',
    deduction: '',
    misc: '50000',
  };
  const [searchParams, setSearchParams] = useState(defValue);
  const [result, setResult] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [result]);

  return (
    <div className='mainContainer'>
      {result ? (
        <Results
          setResult={setResult}
          result={result}
          defValue={defValue}
          setSearchParams={setSearchParams}
        />
      ) : (
        <SearchField
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          setResult={setResult}
          defValue={defValue}
          result={result}
          error={error}
          setError={setError}
        />
      )}
    </div>
  );
};

export default MainScreen;
