import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const DialogBox = ({ open, handleClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        maxWidth='lg'
      >
        <DialogTitle id='responsive-dialog-title'>
          {'Átalányadó esetén alkalmazandó költséghányadok: '}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>
              Minden, az alább felsoroltak közé nem tartozó tevékenység 40%-os
              kulccssal számítandó.
            </strong>
            <br />
            <br />
            <strong>
              "90%-os költséghányadot az alkalmazhatja, aki az adóév egészében
              kizárólag kiskereskedelmi tevékenységből szerez bevételt (azaz más
              tevékenységből nem lehet bevétele), illetve a mezőgazdasági
              őstermelő.
            </strong>
            <br />
            <br />
            <strong>
              80%-os költséghányadot az alkalmazhat, aki az adóév egészében az
              alábbi tevékenységek valamelyikéből szerez bevételt:
            </strong>
            <br />
            <br />
            mezőgazdasági,erdőgazdálkodási(TESZOR01,02)
            <br />
            <br />
            bányászati (TESZOR 05-től 09-ig) és feldolgozóipari (TESZOR 10-től
            32-ig) termék-előállítás
            <br />
            <br />
            építőipari kivitelezés (TESZOR 41, 42)
            <br />
            <br />
            mezőgazdasági, betakarítást követő szolgáltatás (TESZOR 01.6),
            vadgazdálkodáshoz kapcsolódó szolgáltatás (TESZOR 01.70.10),
            erdészeti szolgáltatás (TESZOR 02.40.10) és zöldterület-kezelés
            (TESZOR 81.30.10)
            <br />
            <br />
            halászati szolgáltatás (TESZOR 03.00.71), halgazdálkodási
            szolgáltatás (TESZOR 03.00.72)
            <br />
            <br />
            feldolgozóipari szolgáltatás (TESZOR 10-től 32-ig) a bérmunkában
            végzett szolgáltatás és az egyéb sokszorosítás (TESZOR 18.20)
            kivételével
            <br />
            <br />
            építőipari szolgáltatás (TESZOR 43)
            <br />
            <br />
            iparigép, berendezés, eszközjavítása (TESZOR 33.1), gépjárműjavítás
            (TESZOR 45.20), személyi, háztartási cikk javítása (TESZOR 95.2),
            épületgépészeti berendezések javítása (TESZOR 43.21, 43.22, 43.29)
            <br />
            <br />
            taxis személyszállítás (TESZOR 49.32.11) személygépjármű kölcsönzése
            vezetővel (TESZOR 49.32.12), egyéb máshová nem sorolt szárazföldi
            személyszállítás (TESZOR 49.39.39), közúti áruszállítás (TESZOR
            49.41.1)
            <br />
            <br />
            számítógép, kommunikációs eszköz javítása (TESZOR 95.1) fényképészet
            (TESZOR 74.20)
            <br />
            <br />
            textil, szőrme mosása, tisztítása (TESZOR 96.01), fodrászat,
            szépségápolás (TESZOR 96.02), hobbiállat-gondozás (TESZOR 96.09.11)
            <br />
            <br />
            a kereskedelmi tevékenységek végzésének feltételeiről szóló
            kormányrendelet alapján folytatott vendéglátó tevékenység (TESZOR
            56)
            <br />
            <br />
            kiskereskedelmi tevékenységből bevételt szerző egyéni vállalkozó: ha
            nem kizárólag kiskereskedelmi tevékenységet végez, hanem mellette
            van másik kedvezményesen végzett tevékenység is, akkor 80%-os
            költséghányadot lehet csak alkalmazni
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Bezár
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogBox;
