const exemptedInc = 1200000;
const fRExpenseCounter = (data) => {
  let fRExp = 0;
  fRExp = Number(data.income.replace(/\D/g, '')) * Number(data.deduction);
  return fRExp;
};

const minContBaseCalc = (data) => {
  let minContBase = 0;
  let contBase = 0;
  if (data.fulltime === 'true') {
    if (data.qualification === 'true') {
      contBase = 3120000;
    } else {
      contBase = 2400000;
    }
    minContBase = contBase * 1.125;
  }
  return minContBase;
};

const minTaxBaseCalc = (data) => {
  let taxBase = 0;
  if (data.qualification === 'true') {
    taxBase = 3120000;
  } else {
    taxBase = 2400000;
  }
  return taxBase;
};

const compFRTaxBaseCalc = (data) => {
  let compFRTaxBase = 0;
  let fRExp = fRExpenseCounter(data);
  if (Number(data.income.replace(/\D/g, '')) - fRExp - exemptedInc > 0) {
    compFRTaxBase =
      Number(data.income.replace(/\D/g, '')) - fRExp - exemptedInc;
  }
  return compFRTaxBase;
};

const contributionCalc = (data) => {
  let minContBase = minContBaseCalc(data);
  let compFRTaxBase = compFRTaxBaseCalc(data);
  let cont = 0;
  if (data.retired === 'false') {
    if (data.fulltime === 'true') {
      cont = Math.max(minContBase, compFRTaxBase) * 0.13;
    } else if (data.fulltime === 'false') {
      cont = compFRTaxBase * 0.13;
    }
  }
  return cont;
};

const socialSecCalc = (data) => {
  let socialSec = 0;
  let compFRTaxBase = compFRTaxBaseCalc(data);
  // console.log(compFRTaxBase, 'compFRTaxBase in socialsec');
  let minTaxBase = minTaxBaseCalc(data);
  // console.log(minTaxBase, 'minTaxBase in socialsec');
  if (data.retired === 'false') {
    if (data.fulltime === 'true') {
      socialSec = Math.max(compFRTaxBase, minTaxBase) * 0.185;
    } else {
      socialSec = compFRTaxBase * 0.185;
    }
  }
  return socialSec;
};

const businessTaxCalc = (data) => {
  let businessTax = 0;
  let fRExp = fRExpenseCounter(data);
  let profit = Number(data.income.replace(/\D/g, '')) - fRExp;
  // console.log('profit', profit);
  // console.log('fRExp', fRExp);
  // console.log('profit', profit);
  if (
    Math.max(0, profit) * Number(data.tax.replace(/\D/g, '')) * 0.01 * 1.2 >=
    Number(data.income.replace(/\D/g, '')) *
      (Number(data.tax.replace(/\D/g, '')) * 0.01) *
      0.8
  ) {
    businessTax =
      Number(data.income.replace(/\D/g, '')) *
      (Number(data.tax.replace(/\D/g, '')) * 0.01) *
      0.8;
  } else {
    businessTax =
      Math.max(0, profit) * Number(data.tax.replace(/\D/g, '')) * 0.01 * 1.2;
  }
  return businessTax;
};

const incomeTaxCalc = (data) => {
  let compFRTaxBase = compFRTaxBaseCalc(data);
  let incomeTax = compFRTaxBase * 0.15;
  return incomeTax;
};

export const fRTax = (data) => {
  let FRTax = 0;
  let contribution = contributionCalc(data);
  let socialSecurity = socialSecCalc(data);
  let businessTax = businessTaxCalc(data);
  let incomeTax = incomeTaxCalc(data);

  FRTax = contribution + socialSecurity + businessTax + incomeTax;

  // console.log(contribution, 'contribution');
  // console.log(socialSecurity, 'socialSecurity');
  // console.log(businessTax, 'businessTax');
  // console.log(incomeTax, 'incomeTax');
  return FRTax;
};
