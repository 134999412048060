const yearlyMinWage = 2400000;
const yearlyMinSalary = 3120000;

const incomeTaxCalc = (data) => {
  let incomeTax = 0;
  if (Number(data.withdrawals.replace(/\D/g, '')) * 12 > 0) {
    if (Number(data.withdrawals.replace(/\D/g, '')) * 12 > yearlyMinWage) {
      incomeTax = Number(data.withdrawals.replace(/\D/g, '')) * 0.15 * 12;
    } else {
      incomeTax = Number(data.withdrawals.replace(/\D/g, '')) * 0.15 * 12;
    }
  }
  return incomeTax;
};
const minContBaseCalc = (data) => {
  let minContBase = 0;
  let contBase = 0;
  if (data.employed === 'false') {
    if (data.fulltime === 'true') {
      if (data.qualification === 'true') {
        contBase = yearlyMinSalary;
      } else {
        contBase = yearlyMinWage;
      }
      minContBase = contBase * 1.125;
    }
  }
  return minContBase;
};
const lowerTaxBaseCalc = (data) => {
  let lowerTaxBase = 0;
  if (data.employed === 'true') {
    if (
      Number(data.withdrawals.replace(/\D/g, '')) * 12 <
      yearlyMinWage * 0.3
    ) {
      lowerTaxBase = yearlyMinWage * 0.3;
    }
  }
  // console.log(lowerTaxBase, 'lovertaxbez');
  return lowerTaxBase;
};

const entTaxCalc = (data) => {
  let entTax = 0;
  let minContBase = minContBaseCalc(data);
  let lowerTaxBase = lowerTaxBaseCalc(data);
  if (data.retired === 'false') {
    entTax =
      Math.max(minContBase, lowerTaxBase, data.withdrawals.replace(/\D/g, '')) *
      12 *
      0.1;
  }
  return entTax;
};

// const minTaxBaseCalc = (data) => {
//   let minTaxBase = 0;
//   if (data.employed === 'false') {
//     if (data.fulltime === 'true') {
//       if (data.qualification === 'true') {
//         minTaxBase = yearlyMinSalary;
//       } else {
//         minTaxBase = yearlyMinWage;
//       }
//     }
//   }
//   return minTaxBase;
// };

const socialSecCalc = (data, minContBase, lowerTaxBase) => {
  let socialSec = 0;
  // console.log('lowerTaxBase', lowerTaxBase);
  // console.log('minContBase', minContBase);
  if (data.retired === 'false') {
    // if (data.employed === 'true') {
    socialSec =
      Math.max(
        Number(
          data.withdrawals.replace(/\D/g, '') * 12,
          lowerTaxBase,
          minContBase
        )
      ) * 0.185;
    // }
  }
  return socialSec;
};

const smallEntTaxBaseCalc = (data) => {
  let smallEntTaxBase = 0;
  let socialSec = socialSecCalc(data);
  smallEntTaxBase =
    Number(data.income.replace(/\D/g, '')) -
    socialSec -
    Number(data.misc.replace(/\D/g, '')) * 12;
  return smallEntTaxBase;
};

const normalBusinessTaxCalc = (data) => {
  let normBusinessTax = 0;
  if (Number(data.income.replace(/\D/g, '')) > 8000000) {
    normBusinessTax =
      Number(data.income.replace(/\D/g, '')) *
      Number(data.tax.replace(/\D/g, '') * 0.01);
  } else {
    normBusinessTax =
      Number(data.income.replace(/\D/g, '')) *
      0.8 *
      Number(data.tax.replace(/\D/g, '') * 0.01);
  }
  return normBusinessTax;
};

const businessTaxCalc = (data) => {
  let businessTax = 0;
  let smallEntTaxBase = smallEntTaxBaseCalc(data);
  let normaBusTax = normalBusinessTaxCalc(data);
  let a = smallEntTaxBase * 1.2 * Number(data.tax.replace(/\D/g, '')) * 0.01;
  let c =
    Number(data.withdrawals.replace(/\D/g, '')) *
    12 *
    1.2 *
    Number(data.tax.replace(/\D/g, '')) *
    0.01;
  if (Number(data.withdrawals.replace(/\D/g, '')) * 12 > 0) {
    businessTax = Math.min(a, normaBusTax, c);
  }
  return businessTax;
};

const totalExpensesCalc = (data) => {
  let totalExpenses = 0;
  let entTax = entTaxCalc(data);
  let businessTax = businessTaxCalc(data);
  totalExpenses =
    Number(data.withdrawals.replace(/\D/g, '')) * 12 +
    entTax +
    businessTax +
    Number(data.misc.replace(/\D/g, '')) * 12;
  return totalExpenses;
};

const dividendBaseCalc = (data) => {
  let dividendBase = 0;
  let totalExpenses = totalExpensesCalc(data);
  dividendBase = Number(data.income.replace(/\D/g, '')) - totalExpenses;
  return dividendBase;
};

const profiTaxCalc = (data) => {
  let profitTax = 0;
  let dividendBase = dividendBaseCalc(data);
  if (data.retired === 'false') {
    profitTax = dividendBase * 0.1;
  }
  return profitTax;
};
const dividendTaxCalc = (data) => {
  let dividendTax = 0;
  let dividendBase = dividendBaseCalc(data);
  if (dividendBase > 0) {
    dividendTax = dividendBase * 0.15;
  }
  return dividendTax;
};

const auxContFunc = (data) => {
  let aux = 0;
  if (data.fulltime === 'true') {
    if (data.qualification === 'true') {
      let aux2 = 0;
      if (data.fulltime === 'true') {
        aux2 = 3120000 * 1.125;
      } else {
        aux2 = 3120000;
      }
      aux = Math.max(Number(data.withdrawals.replace(/\D/g, '')) * 12, aux2);
    } else {
      let aux3 = 0;
      if (data.fulltime === 'true') {
        aux3 = 2400000 * 1.125;
      } else {
        aux3 = 240000;
      }
      aux = Math.max(Number(data.withdrawals.replace(/\D/g, '')) * 12, aux3);
    }
  }
  return aux;
};

const contributionBaseCalc = (data) => {
  let contributionBase = 0;
  let aux = auxContFunc(data);
  if (data.fulltime === 'true') {
    contributionBase = Math.max(
      Number(data.withdrawals.replace(/\D/g, '')) * 12,
      aux
    );
  } else {
    contributionBase = Math.max(
      Number(data.withdrawals.replace(/\D/g, '')) * 12,
      aux
    );
  }
  // console.log(contributionBase, 'contributionBase');
  return contributionBase;
};

// const maxContCalc = () => {
//   return 5280000;
// };

// const divMinContBaseCalc = (data) => {
//   let divMinContBase = 0;
//   if (data.fulltime === 'true') {
//     if (data.qualification === 'true') {
//       divMinContBase = Math.max(
//         Number(data.withdrawals.replace(/\D/g, '')),
//         yearlyMinSalary * 1.125
//       );
//     } else {
//       divMinContBase = Math.max(
//         Number(data.withdrawals.replace(/\D/g, '')),
//         yearlyMinWage * 1.125
//       );
//     }
//   }
//   return divMinContBase;
// };

// const maxContBaseCalc = (data) => {
//   let maxContBase = 0;
//   maxContBase = Number(data.income.replace(/\D/g, ''));
// };

const dividendRelatedMaxContBase = (data, totalExpenses) => {
  let divRelMaxCBase = 0;
  let dividendBase = dividendBaseCalc(data, totalExpenses);
  let maxContBase = 5280000;
  let minContBase = contributionBaseCalc(data);
  // console.log(dividendBase, 'dividendBase');
  // console.log(maxContBase, 'maxContBase');
  // console.log(minContBase, 'minContBase');
  divRelMaxCBase = Math.min(dividendBase, maxContBase) - minContBase;
  return divRelMaxCBase;
};

const dividendContributionCalc = (data, divRelMaxCBase) => {
  let dividendContribution = 0;
  let dividendBase = dividendBaseCalc(data);
  // console.log(divRelMaxCBase, 'divRelMaxCBase in divcontcalc');
  // console.log(dividendBase, 'dividendBase in divcontcalc');
  if (data.retired === 'false') {
    if (dividendBase > 0) {
      if (Number(data.withdrawals.replace(/\D/g, '')) * 12 > 5280000) {
        dividendContribution = 0;
      } else {
        if (divRelMaxCBase > 0) {
          dividendContribution = divRelMaxCBase * 0.13;
        }
      }
    }
  }
  return dividendContribution;
};

export const smallEntTax = (data) => {
  let smallEntTax = 0;
  let incomeTax = incomeTaxCalc(data);
  let entTax = entTaxCalc(data);
  let minContBase = minContBaseCalc(data);
  let lowerTaxBase = lowerTaxBaseCalc(data);
  let socialSec = socialSecCalc(data, minContBase, lowerTaxBase);
  let businessTax = businessTaxCalc(data);
  // let totalExpenses = totalExpensesCalc(data);
  let profitTax = profiTaxCalc(data);
  // let dividendBase = dividendBaseCalc(data, totalExpenses);

  let dividendTax = dividendTaxCalc(data);
  let divRelMaxCBase = dividendRelatedMaxContBase(data);
  let dividendContribution = dividendContributionCalc(data, divRelMaxCBase);

  // console.log(incomeTax, 'incomeTax');
  // console.log(entTax, 'entTax');
  // console.log(minContBase, 'minContBase');
  // console.log(lowerTaxBase, 'lowerTaxBase');
  // console.log(socialSec, 'socialSec');
  // console.log(businessTax, 'businessTax');
  // console.log(totalExpenses, 'totalExpenses');
  // console.log(profitTax, 'profitTax');
  // console.log(dividendTax, 'dividendTax');
  // console.log(dividendContribution, 'dividendContribution');

  smallEntTax =
    incomeTax +
    entTax +
    socialSec +
    businessTax +
    profitTax +
    dividendTax +
    dividendContribution;

  return smallEntTax;
};
