import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import MainScreen from './views/MainScreen';
import NoContent from './views/404';

import { Routes, Route, Navigate } from 'react-router-dom';

function App() {
  return (
    <div className='App'>
      <Header />
      <Routes>
        <Route path='/' element={<MainScreen />} />
        <Route path='/main' element={<Navigate to='/' />} />
        <Route path='/search' element={<Navigate to='/' />} />
        <Route path='/*' element={<NoContent />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
