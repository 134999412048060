import image from '../Vector.png';
import React from 'react';

const InfoImg = React.forwardRef(function InfoImg(props, ref) {
  return (
    <img {...props} ref={ref} src={image} alt='Info Icon' className='infoImg' />
  );
});

export default InfoImg;
