import { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import toon from '../../src/toon.png';
const elevation = 0;

const Results = ({ result, setResult }) => {
  const backBtnHandler = (e) => {
    setResult(null);
  };
  const [sorted, setSorted] = useState();
  const [winner, setWinner] = useState();
  const [widths, setWidths] = useState([]);

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  };

  function removeDigits(x, n) {
    return (x - (x % Math.pow(10, n))) / Math.pow(10, n);
  }

  const roundOutput = (data) => {
    return data && Math.round(data * 0.001) * 1000;
  };

  useEffect(() => {
    setSorted(
      result.sort((a, b) => {
        return a.value - b.value;
      })
    );
    sorted && setWinner(sorted[0]);
  }, [sorted, winner, result]);

  useEffect(() => {
    let width1 = sorted && Object.values(sorted[0])[1];
    let width2 = sorted && Object.values(sorted[1])[1];
    let width3 = sorted && Object.values(sorted[sorted.length - 2])[1];
    let width4 = sorted && Object.values(sorted[sorted.length - 1])[1];
    sorted &&
      setWidths([
        Math.round((width1 / width4) * 100),
        Math.round((width2 / width4) * 100),
        Math.round((width3 / width4) * 100),
        Math.round((width4 / width4) * 100),
      ]);
  }, [sorted, setWidths]);

  return (
    <div className='resultContainer'>
      <button
        type='button'
        className='secondaryBtnDefault'
        onClick={backBtnHandler}
      >
        Vissza
      </button>
      <div className='paperBg'>
        <Paper elevation={elevation} className='muiPaper'>
          <div className='resultOutput'>
            <p className='formTitle'>Gratulálunk! </p>
            <p className='caption'>
              {' '}
              Remekül adtad meg az adataid, amik alapján azt látjuk, hogy a(z)
              <br />
              <span className='bookieLink'>
                {winner && winner.taxName}
              </span>{' '}
              lenne számodra az adózásilag leginkább megfelelő vállalkozási
              forma.
            </p>{' '}
            <p className='slimText'>
              {' '}
              (Bár az eredmények valószínűleg stimmelnek, kérjük, hogy kezeld
              ezt egy irányadó kalkulációnak, teljesen pontos és végleges
              válaszért konzultálj egy könyvelővel!)
            </p>
          </div>
        </Paper>
      </div>
      <div className='paperBg'>
        <Paper elevation={elevation} className='resultOutputContainer muiPaper'>
          <p className='stepsTitle'>Eredmények </p>

          {sorted &&
            sorted.map((e, i) => {
              return (
                <div key={i} className='resRow'>
                  <span className='resPlace'>{i + 1}</span>.
                  <span className='resOutName'>
                    {e.taxName}
                    {': '}
                  </span>{' '}
                  <br />
                  <p className='resultOuter'>
                    <span
                      className={`resultValue place${i}`}
                      style={{ width: `${widths[i]}%` }}
                    ></span>
                    <span className='resultNr'>
                      <strong>
                        {numberWithCommas(
                          removeDigits(roundOutput(e.value), 3)
                        )}
                      </strong>
                      {' e Ft  fizetendő adó'}
                    </span>
                  </p>
                </div>
              );
            })}
          <br />
        </Paper>
      </div>
      <div className='paperBg'>
        <Paper elevation={elevation} className='muiPaper btnContainer'>
          <img className='toon' src={toon} alt='toon' />
          <p className='stepsTitle'>
            Már csak{' '}
            {winner && winner.taxName.toLowerCase().includes('kft')
              ? 'két'
              : 'egy'}{' '}
            feladatunk van.
          </p>
          <div className='contFlex'>
            <a
              className='primaryBtnDefault marginTop28'
              href='https://www.konyvelo-net.hu/'
              target='_blank'
              rel='noreferrer'
            >
              Bízzunk meg egy jó könyvelőt!
            </a>
            {winner && winner.taxName.toLowerCase().includes('kft') && (
              <a
                className='linkedBtnDefault marginTop28'
                href='https://www.heroik.io/'
                target='_blank'
                rel='noreferrer'
              >
                Alapítsunk céget - egyszerűen, online.
              </a>
            )}
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default Results;
