import { useCallback, useEffect, useState } from 'react';
import { sETax } from '../utils/calcSelfEmp';
import { fRTax } from '../utils/calcFlatRate';
import { corpTax } from '../utils/corpTax';
import { smallEntTax } from '../utils/smallEntTax';

import { Paper } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DialogBox from './Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { yellow, indigo } from '@mui/material/colors';

import NumberFormat from 'react-number-format';

import InfoImg from './InfoImg';
const elevation = 0;

const SearchField = ({
  searchParams,
  setSearchParams,
  setResult,
  error,
  setError,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (
  //     searchParams.fulltime === 'true' &&
  //     searchParams.employed === 'true' &&
  //     searchParams.withdrawals === ''
  //   ) {
  //     if (searchParams.qualification === 'true') {
  //       setSearchParams((p) => ({ ...p, withdrawals: 3120000 }));
  //     } else if (searchParams.qualification === 'false') {
  //       setSearchParams((p) => ({ ...p, withdrawals: 2400000 }));
  //     }
  //   }
  // }, [searchParams]);

  const changeHandler = useCallback(
    (event) => {
      setSearchParams((p) => ({
        ...p,
        [event.target.name]: event.target.value,
      }));
      setError(false);
    },
    [setSearchParams, setError]
  );

  const resultSetter = () => {
    let selfTax = [
      {
        taxName: 'Egyéni vállalkozó - vállalkozói jövedelemadóval',
        value: Math.round(sETax(searchParams)),
      },
    ];
    let flatTax = null;
    if (Number(searchParams.income.replace(/\D/g, '')) < 24000000) {
      flatTax = {
        taxName: 'Egyéni vállalkozó - átalányadóval',
        value: Math.round(fRTax(searchParams)),
      };
    }
    let corpIncTax = {
      taxName: 'Kft. / Bt. - TAO-val',
      value: Math.round(corpTax(searchParams)),
    };
    let smallTax = null;
    if (Number(searchParams.income.replace(/\D/g, '')) < 3000000000) {
      smallTax = {
        taxName: 'Kft. / Bt. - KIVA-val',
        value: Math.round(smallEntTax(searchParams)),
      };
    }
    setResult(selfTax);
    setResult((p) => [...p, corpIncTax]);
    if (flatTax !== null) {
      setResult((p) => [...p, flatTax]);
    }
    if (smallTax !== null) {
      setResult((p) => [...p, smallTax]);
    }
  };

  useEffect(() => {
    Object.values(searchParams).forEach((e) => {
      if (e === '') {
        setError(true);
      }
    });
  }, [searchParams, setError]);

  const submitHandler = (event) => {
    event.preventDefault();
    if (error === true) {
    } else if (error === false) {
      resultSetter();
    }
  };

  return (
    <>
      <div className='paperBg'>
        <Paper className='muiPaper' elevation={elevation}>
          <p className='formTitle'>Egyszerűsített kalkulátor</p>
          <div className='caption'>
            Ez egy egyszerűsített kalkulátor a{' '}
            <span className='bookieLink'>
              <a
                href='https://www.konyvelo-net.hu/'
                target='_blank'
                rel='noreferrer'
              >
                Bookkeepie
              </a>
            </span>{' '}
            jóvoltából, ami megmondja, hogy milyen cég / adózási forma lenne
            számodra a leginkább megfelelő és hogy nagyságrendileg milyen
            adófizetési kötelezettséged keletkezne évente*.
          </div>
        </Paper>
      </div>
      <div className='paperBg'>
        <Paper className='muiPaper' elevation={elevation}>
          <Box component='form' onSubmit={submitHandler}>
            <p className='stepsTitle'>
              Töltsd ki az alábbi pár adatot, hogy kalkulálni tudjunk:
            </p>
            <div className='formInput'>
              <FormControl
                className='formControlClass'
                onSubmit={submitHandler}
              >
                <FormLabel className='formLabel' id='income'>
                  Mennyi az éves bevételed?{' '}
                  <span className='blueText'>(forintban)</span>
                </FormLabel>
                <NumberFormat
                  value={searchParams.income}
                  onChange={changeHandler}
                  className='textInput marginBottom28'
                  size='small'
                  variant='outlined'
                  name='income'
                  id='income'
                  customInput={TextField}
                  thousandSeparator
                  isNumericString
                />
              </FormControl>
              <FormControl className='formControlClass'>
                <FormLabel className='formLabel marginTop28' id='fulltime'>
                  Főállású vállalkozó vagy{' '}
                  <span className='spanSlim'>
                    (aki más módon <span className='blueText'>NEM</span>{' '}
                    biztosított)?
                  </span>
                  <br />
                  <span className='blueText'>
                    (Nyugdíjasként nem lehetsz főállású.)
                  </span>
                  <Tooltip
                    className='toolTip'
                    placement='right-end'
                    disableFocusListener
                    title='Ha főállású vagy, az azt jelenti, hogy nincs máshol legalább 36 órát elérő munkaviszonyod,
                vagy olyan társas/egyéni vállalkozói jogviszonyod, ami után a járulékok megfizetésre kerülnek.
                Ha mellékállású vagy, akkor nem kell a minimálbér - vagy garantált bérminimum - után megfizetni a tb járulékot,
                csak a ténylegesen elért jövedelem után. Ha ez nulla, akkor járulékot sem kell fizetni
                - ebben az esetben a kivét összegére írj nullát! '
                  >
                    <InfoImg />
                  </Tooltip>
                </FormLabel>
                <RadioGroup
                  id='fulltime'
                  name='fulltime'
                  onChange={changeHandler}
                  value={searchParams.fulltime}
                >
                  <FormControlLabel
                    className='radioLabel'
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          '&.Mui-checked': {
                            color: yellow[600],
                          },
                        }}
                      />
                    }
                    label={'Igen'}
                    value={'true'}
                  />
                  <FormControlLabel
                    className='radioLabel'
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          '&.Mui-checked': {
                            color: yellow[600],
                          },
                        }}
                      />
                    }
                    label={'Nem'}
                    value={'false'}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl className='formControlClass'>
                <FormLabel className='formLabel marginTop28' id='retired'>
                  Nyugdíjasként vállalkozol?
                </FormLabel>
                <RadioGroup
                  id='retired'
                  name='retired'
                  onChange={changeHandler}
                  value={searchParams.retired}
                >
                  <FormControlLabel
                    className='radioLabel'
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          '&.Mui-checked': {
                            color: yellow[600],
                          },
                        }}
                      />
                    }
                    label={'Igen'}
                    value={'true'}
                  />
                  <FormControlLabel
                    className='radioLabel'
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          '&.Mui-checked': {
                            color: yellow[600],
                          },
                        }}
                      />
                    }
                    label={'Nem'}
                    value={'false'}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl className='formControlClass'>
                <FormLabel className='formLabel marginTop28' id='qualification'>
                  Kell legalább középfokú végzettség a tevékenység végzéséhez?
                  <span className='spanSlim'>
                    {' '}
                    <br />
                    (Ehhez a{' '}
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.ksh.hu/docs/szolgaltatasok/hun/feor08/feorlista.html#'
                    >
                      FEOR-besorolás
                    </a>{' '}
                    ad támpontot)
                  </span>
                </FormLabel>
                <RadioGroup
                  id='qualification'
                  name='qualification'
                  onChange={changeHandler}
                  value={searchParams.qualification}
                >
                  <FormControlLabel
                    className='radioLabel'
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          '&.Mui-checked': {
                            color: yellow[600],
                          },
                        }}
                      />
                    }
                    label={'Igen'}
                    value={'true'}
                  />
                  <FormControlLabel
                    className='radioLabel'
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          '&.Mui-checked': {
                            color: yellow[600],
                          },
                        }}
                      />
                    }
                    label={'Nem'}
                    value={'false'}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl className='formControlClass'>
                <FormLabel className='formLabel marginTop28' id='employed'>
                  Ha cégben gondolkozol, munkaviszonyban lennél a cégedben?
                  <Tooltip
                    className='toolTip'
                    placement='right-end'
                    disableFocusListener
                    title={`válasz esetén a kalkulátor úgy számol, mintha az ügyvezető munkaviszonyban lenne a céggel
                - ez mellékállás esetén releváns. Főállás esetén legalább a minimálbért vagy 
                - ha a tevékenység végzése legalább középfokú végzettséget igényel - a garantált bérminimumot kell beírni.`}
                  >
                    <InfoImg />
                  </Tooltip>
                </FormLabel>
                <RadioGroup
                  id='employed'
                  name='employed'
                  onChange={changeHandler}
                  value={searchParams.employed}
                >
                  <FormControlLabel
                    className='radioLabel'
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          '&.Mui-checked': {
                            color: yellow[600],
                          },
                        }}
                      />
                    }
                    label={'Igen'}
                    value={'true'}
                  />
                  <FormControlLabel
                    className='radioLabel'
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          '&.Mui-checked': {
                            color: yellow[600],
                          },
                        }}
                      />
                    }
                    label={'Nem'}
                    value={'false'}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl className='formControlClass'>
                <FormLabel className='formLabel marginTop28' id='withdrawals'>
                  Mennyi pénzt szeretnél kivenni a vállalkozásból, vagy
                  munkaviszony esetén mennyi lenne a havi bruttó béred?{' '}
                  <span className='blueText'>(Forint)</span>
                  <Tooltip
                    className='toolTip'
                    placement='right-end'
                    disableFocusListener
                    title='Egyéni vállalkozóként tulajdonképpen magadat foglalkoztatod. Ezért a bevételeidből úgymond kivett összeg kvázi fizetésként funkcionál.
                Ennek nagysága rajtad múlik. Ez után adót ás járulékot kell fizetned - főállású vállalkozóként legalább a minimálbér
                vagy garantált bérminimum után számított összeget. Ennek indoka,
                hogy mindenki biztosított legyen és fizessen járulékot, aki dolgozik.
                Mellékállásúként, ha valahol már fizetsz/fizetnek utánad járulékokat, akkor csak a tényleges kivét után kell ezeket megfizetni. 
                Főállásban vagy munkaviszony esetén ez az összeg legalább a havi minimálbér/garantált bérminimum összege legyen.'
                  >
                    <InfoImg />
                  </Tooltip>
                </FormLabel>
                <NumberFormat
                  value={searchParams.withdrawals}
                  onChange={changeHandler}
                  className='textInput marginBottom28'
                  size='small'
                  variant='outlined'
                  name='withdrawals'
                  id='withdrawals'
                  customInput={TextField}
                  thousandSeparator
                  isNumericString
                />
              </FormControl>

              <FormControl className='formControlClass'>
                <FormLabel className='formLabel marginTop28' id='tax'>
                  Iparűzési adó mértéke <span className='blueText'>(%)</span>{' '}
                  <span className='spanSlim'>
                    (a székhelyed szerinti önkormányzat határozza meg)
                  </span>
                </FormLabel>
                <TextField
                  className='textInput'
                  size='small'
                  id='tax'
                  name='tax'
                  onChange={changeHandler}
                  value={searchParams.tax}
                />
              </FormControl>
              <FormControl className='formControlClass'>
                <FormLabel className='formLabel marginTop28' id='deduction'>
                  Tevékenységed alapján melyik költséghányadra vagy jogosult?{' '}
                  <span onClick={handleClickOpen}>
                    <InfoImg />
                  </span>
                </FormLabel>
                <RadioGroup
                  id='deduction'
                  name='deduction'
                  onChange={changeHandler}
                  value={searchParams.deduction}
                >
                  <FormControlLabel
                    className='radioLabel'
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          '&.Mui-checked': {
                            color: yellow[600],
                          },
                        }}
                      />
                    }
                    label='40%'
                    value={'0.4'}
                  />
                  <FormControlLabel
                    className='radioLabel'
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          '&.Mui-checked': {
                            color: yellow[600],
                          },
                        }}
                      />
                    }
                    label='80%'
                    value={'0.8'}
                  />
                  <FormControlLabel
                    className='radioLabel'
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          '&.Mui-checked': {
                            color: yellow[600],
                          },
                        }}
                      />
                    }
                    label='90%'
                    value={'0.9'}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl className='formControlClass'>
                <FormLabel className='formLabel marginTop28 ' id='misc'>
                  Költségek havi szinten{' '}
                  <span className='blueText'>(Ft) (könyvelő, bank, stb)</span>
                  <Tooltip
                    className='toolTip'
                    placement='right-end'
                    disableFocusListener
                    title='Az egyszerűség kedvéért beírtunk ide egy átlagos havi ügyviteli költség (könyvelő, bank, stb) becslést.
                   Ha ezen felül van bármilyen költséged (bérek, alvállalkozók, anyagbeszerzés, stb), azt is itt tüntesd fel.'
                  >
                    <InfoImg />
                  </Tooltip>
                </FormLabel>
                <NumberFormat
                  value={searchParams.misc}
                  onChange={changeHandler}
                  className='textInput marginBottom28'
                  size='small'
                  variant='outlined'
                  name='misc'
                  id='misc'
                  customInput={TextField}
                  thousandSeparator
                  isNumericString
                />
              </FormControl>
            </div>
            <p className='stepsTitle marginTop48'>
              Ha kitöltöttél mindent akkor kattints a gombra, hogy elvégezzük a
              számítást:
            </p>

            <button
              type='submit'
              disabled={error}
              className={` marginTop28 ${
                error ? 'disabledBtn' : 'primaryBtnDefault'
              }`}
            >
              Számoljunk!
            </button>
          </Box>
        </Paper>
      </div>
      <br />
      <div className='paperBg'>
        <Paper className='muiPaper' elevation={elevation}>
          <div className='disclaimer'>
            * A kalkulátor használata NEM helyettesíti a könyvelővel való
            egyeztetést, ugyanis a kalkulátor nem vesz figyelembe minden tényt
            és körülményt (pl. adókedvezmények, kapcsolt vállalkozások, a
            bevételek éven belüli megoszlása, stb.), ami kihatással lehet az
            egyes adózási és vállalkozási formák közti választásra. A Bookkeepie
            nem vállal felelősséget semmilyen, a kalkulátor eredményei alapján
            hozott döntés nyomán keletkező esetleges kárért vagy kieső
            haszonért.
          </div>
        </Paper>
        <DialogBox open={open} handleClose={handleClose} />
      </div>
    </>
  );
};

export default SearchField;
