const yearlyMinWage = 2400000;
const yearlyMinSalary = 3120000;

const incomeTaxCalc = (withdrawals) => {
  let incomeTax = 0;
  if (withdrawals !== 0) {
    incomeTax = Number(withdrawals.replace(/\D/g, '')) * 0.15 * 12;
  }
  return incomeTax;
};

const auxContFunc = (data) => {
  let aux = 0;
  if (data.fulltime === 'true') {
    if (data.qualification === 'true') {
      let aux2 = 0;
      if (data.fulltime === 'true') {
        aux2 = 3120000 * 1.125;
      } else {
        aux2 = 3120000;
      }
      aux = Math.max(Number(data.withdrawals.replace(/\D/g, '')) * 12, aux2);
    } else {
      let aux3 = 0;
      if (data.fulltime === 'true') {
        aux3 = 2400000 * 1.125;
      } else {
        aux3 = 240000;
      }
      aux = Math.max(Number(data.withdrawals.replace(/\D/g, '')) * 12, aux3);
    }
  }
  return aux;
};

const contributionBaseCalc = (data) => {
  let contributionBase = 0;
  let aux = auxContFunc(data);
  if (data.fulltime === 'true') {
    contributionBase = Math.max(
      Number(data.withdrawals.replace(/\D/g, '')) * 12,
      aux
    );
  } else {
    contributionBase = Math.max(
      Number(data.withdrawals.replace(/\D/g, '')) * 12,
      aux
    );
  }
  // console.log(contributionBase, 'contributionBase');
  return contributionBase;
};

const contcalc = (data) => {
  let contributionBase = 0;
  let contribution = 0;
  if (data.retired === 'true') {
    contribution = 0;
  } else if (data.fulltime === 'false') {
    contribution = Number(data.withdrawals.replace(/\D/g, '')) * 0.13 * 12;
  } else {
    if (data.fulltime === 'true') {
      if (data.qualification === 'true') {
        contributionBase = 3120000 * 1.125;
      } else {
        contributionBase = 2400000 * 1.125;
      }
      contribution =
        Math.max(
          Number(data.withdrawals.replace(/\D/g, '')) * 12,
          contributionBase
        ) * 0.13;
    } else {
      contribution = data.withdrawals * 0.13 * 12;
    }
  }
  return contribution;
};

const socialSecCount = (data) => {
  let contributionBase = 0;
  let socialSecurity = 0;
  if (data.retired === 'true') {
    socialSecurity = 0;
  } else {
    if (data.fulltime === 'true') {
      if (data.qualification === 'true') {
        contributionBase = yearlyMinSalary;
      } else {
        contributionBase = yearlyMinWage;
      }
      socialSecurity =
        Math.max(
          Number(data.withdrawals.replace(/\D/g, '')) * 12,
          contributionBase
        ) * 0.185;
    } else {
      socialSecurity = Number(data.withdrawals.replace(/\D/g, '')) * 0.185 * 12;
    }
  }
  return socialSecurity;
};

const businessTaxCalc = (data) => {
  let businessTax = 0;
  if (Number(data.income.replace(/\D/g, '')) > 8000000) {
    businessTax =
      Number(data.income.replace(/\D/g, '')) *
      Number(data.tax.replace(/\D/g, '') * 0.01);
  } else {
    businessTax =
      Number(data.income.replace(/\D/g, '')) *
      0.8 *
      Number(data.tax.replace(/\D/g, '') * 0.01);
  }
  return businessTax;
};

const totalExpensesCalc = (data, contribution, socialSecurity, businessTax) => {
  let totalExpenses =
    Number(data.withdrawals.replace(/\D/g, '')) * 12 +
    contribution +
    (Number(data.withdrawals.replace(/\D/g, '')) === 0 ? socialSecurity : 0) +
    businessTax +
    Number(data.misc.replace(/\D/g, '')) * 12;
  return totalExpenses;
};

const corporateTaxCalc = (data, totalExpenses) => {
  let corpTax = 0;
  if (Number(data.income.replace(/\D/g, '') - totalExpenses > 0)) {
    corpTax = Number(data.income.replace(/\D/g, '') - totalExpenses) * 0.09;
  }
  return Math.round(corpTax);
};

const dividendBaseCalc = (income, totalExpenses, corporateTax) => {
  let dividendBase =
    Number(income.replace(/\D/g, '')) - totalExpenses - corporateTax;
  return Math.round(dividendBase);
};

const dividendTaxCalc = (dividendBase) => {
  let dividendTax = 0;
  if (dividendBase > 0) {
    dividendTax = dividendBase * 0.15;
  }
  return Math.round(dividendTax);
};

// const minContBaseCalc = (data) => {
//   let minContBase = 0;
//   let contBase = 0;
//   if (data.fulltime === 'true') {
//     if (data.qualification === 'true') {
//       contBase = 3120000 * 1.125;
//     } else {
//       contBase = 2400000 * 1.125;
//     }
//     minContBase = contBase;
//   }
//   return minContBase;
// };

// const maxContBaseCalc = (data, totalExpenses) => {
//   let maxContBase = 0;
//   let cont = contcalc(data);
//   let btax = businessTaxCalc(data);
//   let ctax = corporateTaxCalc(data, totalExpenses);
//   maxContBase =
//     Number(data.income) -
//     cont -
//     Number(data.misc) -
//     btax -
//     Number(data.withdrawals) -
//     ctax;
//   return maxContBase;
// };

const dividendRelatedMaxContBase = (data, totalExpenses, corporateTax) => {
  let divRelMaxCBase = 0;
  let divBase = dividendBaseCalc(data.income, totalExpenses, corporateTax);
  let maxContBase = 5280000;
  let minContBase = contributionBaseCalc(data);
  // console.log(divBase, 'divBase');
  // console.log(maxContBase, 'maxContBase');
  // console.log(minContBase, 'minContBase');
  divRelMaxCBase = Math.min(divBase, maxContBase) - minContBase;
  return divRelMaxCBase;
};

const dividendContributionCalc = (data, dividendBase, divRelMaxCBase) => {
  let dividendContribution = 0;
  if (data.retired === 'false') {
    if (dividendBase > 0) {
      if (Number(data.withdrawals.replace(/\D/g, '')) * 12 > 5280000) {
        dividendContribution = 0;
      } else {
        if (divRelMaxCBase > 0) {
          dividendContribution = divRelMaxCBase * 0.13;
        }
      }
    }
  }
  return dividendContribution;
};

export const sETax = (data) => {
  let selfEmpTax = 0;
  let incomeTax = incomeTaxCalc(data.withdrawals);
  let contribution = contcalc(data);
  let socialSecurity = socialSecCount(data);
  let businessTax = businessTaxCalc(data);
  let totalExpenses = totalExpensesCalc(
    data,
    contribution,
    socialSecurity,
    businessTax
  );
  let corporateTax = corporateTaxCalc(data, totalExpenses);
  let divBase = dividendBaseCalc(data.income, totalExpenses, corporateTax);
  let dividendTax = dividendTaxCalc(divBase);
  // let minContBase = minContBaseCalc(data);
  let divRelMaxCBase = dividendRelatedMaxContBase(
    data,
    totalExpenses,
    corporateTax,
    totalExpenses,
    corporateTax
  );
  let dividendContribution = dividendContributionCalc(
    data,
    divBase,
    divRelMaxCBase
  );
  selfEmpTax =
    incomeTax +
    contribution +
    socialSecurity +
    businessTax +
    corporateTax +
    dividendTax +
    dividendContribution;

  // console.log(divRelMaxCBase, 'divRelMaxCBase');
  // console.log(minContBase, 'minContBase');
  // console.log(incomeTax, 'incomeTax');
  // console.log(contribution, 'contribution');
  // console.log(socialSecurity, 'socialSecurity');
  // console.log(businessTax, 'businessTax');
  // console.log(corporateTax, 'corporateTax');
  // console.log(dividendTax, 'dividendTax');
  // console.log(dividendContribution, 'dividendContribution');

  return selfEmpTax;
};
