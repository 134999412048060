const Header = () => {
  return (
    <div className='header'>
      <a href='https://www.konyvelo-net.hu/' target='_blank' rel='noreferrer'>
        <img
          src='https://uploads-ssl.webflow.com/61557504bbfeb846e350ce6d/627cf20ca23dd71c00feab14_White%20bookeepie%20logo.svg'
          alt=''
          className='logo'
        />
      </a>
    </div>
  );
};

export default Header;
